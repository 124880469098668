import * as React from "react";
import Seo from "../../components/seo";
import AboutContainer from "../../containers/about";
import { translations } from "../../translations";
import type { Translation } from "../../types/main";

const t: Translation = {
	language: "se",
	translations: translations.se,
};

const AboutPage = () => (
	<>
		<Seo title="Om oss" lang="sv" />
		<AboutContainer t={t} />
	</>
);

export default AboutPage;
